import React from "react";
import TrackVisibility from "react-on-screen";
import Counter from "../elements/Counter";
import Pagetitle from "../elements/Pagetitle";
import Skill from "../elements/Skill";
import "./About.scss";
const aboutContent = {
  name: "Arslan",
  avatarImage: "/images/profile-pic.png",
  content:
    "I am Arslan Shahab, Frontend-heavy FullStack developer from Lahore, Pakistan. I have rich experience in web site design and building and customization, also I am good at Mobile App Development.",
};

const progressData = [
  {
    id: 1,
    title: "Web App Development",
    percantage: 85,
    progressColor: "#FFD15C",
  },
  {
    id: 2,
    title: "Mobile App Development",
    percantage: 95,
    progressColor: "#FF4C60",
  },
  {
    id: 3,
    title: "Backend Development",
    percantage: 70,
    progressColor: "#6C6CE5",
  },
];

const skillsData = [
  {
    id: 1,
    title: "React.Js",
    percantage: 90,
    progressColor: "#6C6CE5",
    stack: "frontend",
  },
  {
    id: 2,
    title: "Next.Js",
    stack: "frontend",
    percantage: 90,
    progressColor: "#6C6CE5",
  },
  {
    id: 3,
    title: "React Native",
    stack: "frontend",
    percantage: 90,
    progressColor: "#6C6CE5",
  },
  {
    id: 4,
    title: "Node.Js",
    stack: "backend",
    percantage: 90,
    progressColor: "#FF4C60",
  },
  {
    id: 5,
    title: "Express.Js",
    stack: "backend",
    percantage: 90,
    progressColor: "#FF4C60",
  },
  {
    id: 6,
    title: "JavaScript",
    stack: "frontend",
    percantage: 90,
    progressColor: "#6C6CE5",
  },
  {
    id: 7,
    title: "Typescript",
    stack: "frontend",
    percantage: 90,
    progressColor: "#6C6CE5",
  },
  {
    id: 8,
    title: "jQuery",
    stack: "frontend",
    percantage: 90,
    progressColor: "#6C6CE5",
  },
  {
    id: 9,
    title: "JavaScript",
    stack: "frontend",
    percantage: 90,
    progressColor: "#6C6CE5",
  },
  {
    id: 10,
    title: "HTML5 | CSS 3",
    stack: "frontend",
    percantage: 90,
    progressColor: "#6C6CE5",
  },
  {
    id: 11,
    title: "Redux",
    stack: "frontend",
    percantage: 90,
    progressColor: "#6C6CE5",
  },
  {
    id: 12,
    title: "Firebase",
    stack: "backend",
    percantage: 90,
    progressColor: "#FF4C60",
  },
  {
    id: 13,
    title: "Firestore",
    stack: "backend",
    percantage: 90,
    progressColor: "#FF4C60",
  },
  {
    id: 14,
    title: "Socket IO",
    stack: "backend",
    percantage: 90,
    progressColor: "#FF4C60",
  },
  {
    id: 15,
    title: "MongoDb | Mongoose",
    stack: "backend",
    percantage: 90,
    progressColor: "#FF4C60",
  },
  {
    id: 16,
    title: "MySQL | Sequelize",
    stack: "backend",
    percantage: 90,
    progressColor: "#FF4C60",
  },
  {
    id: 17,
    title: "PostgreSQL | TypeORM",
    stack: "backend",
    percantage: 90,
    progressColor: "#FF4C60",
  },
  {
    id: 18,
    title: "Git",
    stack: "others",
    percantage: 90,
    progressColor: "#FFD15C",
  },
  {
    id: 19,
    title: "Github | Gitlab | BitBucket",
    stack: "others",
    percantage: 90,
    progressColor: "#FFD15C",
  },
  {
    id: 20,
    title: "JIRA | Clickup | Monday",
    stack: "mangTool",
    percantage: 90,
    progressColor: "#FFD15C",
  },
  {
    id: 21,
    title: "SASS/SCSS",
    stack: "frontend",
    percantage: 90,
    progressColor: "#6C6CE5",
  },
  {
    id: 22,
    title: "Material UI",
    stack: "frontend",
    percantage: 90,
    progressColor: "#6C6CE5",
  },
  {
    id: 23,
    title: "Styled Components",
    stack: "frontend",
    percantage: 90,
    progressColor: "#6C6CE5",
  },
  {
    id: 24,
    title: "Tailwind CSS",
    stack: "frontend",
    percantage: 90,
    progressColor: "#6C6CE5",
  },
  {
    id: 25,
    title: "Stripe",
    stack: "backend",
    percantage: 90,
    progressColor: "#FF4C60",
  },
  {
    id: 26,
    title: "Google Maps | Mapbox",
    stack: "backend",
    percantage: 90,
    progressColor: "#FF4C60",
  },
  {
    id: 27,
    title: "Trello | Teams | Slack",
    stack: "mangTool",
    percantage: 90,
    progressColor: "#FFD15C",
  },
  {
    id: 28,
    title: "Azure Devops",
    stack: "mangTool",
    percantage: 90,
    progressColor: "#FFD15C",
  },
  {
    id: 29,
    title: "Figma | Adobe Xd",
    stack: "design",
    percantage: 90,
    progressColor: "#FFD15C",
  },
  {
    id: 30,
    title: "Photoshop",
    stack: "design",
    percantage: 90,
    progressColor: "#FFD15C",
  },
  {
    id: 31,
    title: "AWS | Github Actions",
    stack: "other2",
    percantage: 90,
    progressColor: "#FFD15C",
  },
  {
    id: 32,
    title: "Netlify",
    stack: "other2",
    percantage: 90,
    progressColor: "#FFD15C",
  },
  {
    id: 33,
    title: "Bootstrap",
    stack: "frontend",
    percantage: 90,
    progressColor: "#6C6CE5",
  },
  {
    id: 34,
    title: "Firebase Hosting",
    stack: "other2",
    percantage: 90,
    progressColor: "#FFD15C",
  },
];

const counterData = [
  {
    id: 1,
    title: "Projects completed",
    count: 25,
    icon: "icon-fire",
    iconColor: "#f79500",
  },
  {
    id: 2,
    title: "Cup of coffee",
    count: 1217,
    icon: "icon-cup",
    iconColor: "#c18244",
  },
  {
    id: 3,
    title: "Satisfied clients",
    count: 10,
    icon: "icon-people",
    iconColor: "#6c6ce5",
  },
  {
    id: 4,
    title: "Commits Pushed",
    count: 1335,
    icon: "icon-badge",
    iconColor: "#ff4c60",
  },
];

function About() {
  return (
    <section id="about">
      <div className="container">
        <Pagetitle title="About Me" />
        <div className="row">
          <div className="col-md-3">
            <div className="text-center text-md-left">
              <img
                src={aboutContent.avatarImage}
                alt={aboutContent.name}
                style={{ width: "150px" }}
              />
            </div>
            <div className="spacer d-md-none d-lg-none" data-height="30"></div>
          </div>

          <div className="col-md-9 triangle-left-md triangle-top-sm">
            <div className="rounded bg-white shadow-dark padding-30">
              <div className="row">
                <div className="col-md-6">
                  <p>{aboutContent.content}</p>
                  <div className="mt-3">
                    <a
                      href="/Arslan_Shahab_Resume.pdf"
                      className="btn btn-default"
                      download
                    >
                      Download CV
                    </a>
                  </div>
                  <div
                    className="spacer d-md-none d-lg-none"
                    data-height="30"
                  ></div>
                </div>
                <div className="col-md-6">
                  {progressData.map((progress) => (
                    <TrackVisibility
                      once
                      key={progress.id}
                      className="progress-wrapper"
                    >
                      <Skill progress={progress} />
                    </TrackVisibility>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="spacer" data-height="60"></div>
        <Pagetitle title="Skills/Expertise" />
        <div className="row">
          <div className="col-md-12 triangle-left-md triangle-top-sm">
            <div className="rounded bg-white shadow-dark padding-30">
              <div className="row center">
                <div className="col-md-4">
                  <div className="border_box">
                    <h4 className="text_color_front">Frontend</h4>
                    <ul>
                      {skillsData
                        .filter((s) => s.stack === "frontend")
                        .map((progress) => {
                          return <li>{progress.title}</li>;
                        })}
                    </ul>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="border_box">
                    <h4 className="text_color_back">Backend</h4>
                    <ul>
                      {skillsData
                        .filter((s) => s.stack === "backend")
                        .map((progress) => (
                          <li>{progress.title}</li>
                        ))}
                    </ul>
                  </div>

                  <div className="border_box mt-2">
                    <h4 className="text_color_back_b">Version Control</h4>
                    <ul>
                      {skillsData
                        .filter((s) => s.stack === "others")
                        .map((progress) => (
                          <li>{progress.title}</li>
                        ))}
                    </ul>
                  </div>
                </div>

                <div className="col-md-4 ">
                  <div className="border_box mt-2">
                    <h4 className="text_color_other_y">Management Tools</h4>
                    <ul>
                      {skillsData
                        .filter((s) => s.stack === "mangTool")
                        .map((progress) => (
                          <li>{progress.title}</li>
                        ))}
                    </ul>
                  </div>
                  <div className="border_box mt-2">
                    <h4 className="text_color_other_r">Design</h4>
                    <ul>
                      {skillsData
                        .filter((s) => s.stack === "design")
                        .map((progress) => (
                          <li>{progress.title}</li>
                        ))}
                    </ul>
                  </div>
                  <div className="border_box mt-2">
                    <h4 className="text_color_other_b">CI/CD</h4>
                    <ul>
                      {skillsData
                        .filter((s) => s.stack === "other2")
                        .map((progress) => (
                          <li>{progress.title}</li>
                        ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="spacer" data-height="70"></div>
        <div className="row fix-spacing">
          {counterData.map((counter) => (
            <div key={counter.id} className="col-md-3 col-sm-6">
              <TrackVisibility once>
                <Counter counterItem={counter} />
              </TrackVisibility>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default About;
