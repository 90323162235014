import React from "react";
import Pagetitle from "../elements/Pagetitle";
import Timeline from "../elements/Timeline";

const educationData = [
  {
    id: 2,
    title: "Internship | React.Js",
    years: "Sep 2018 - Dec 2018",
    content: "DH Collator",
  },

  {
    id: 3,
    title: "BS Hons. Information Technology",
    years: "2014 - 2018",
    content: "University of Education, LHR",
  },
];

const experienceData = [
  // {
  //   id: 1,
  //   title: "Employability.Life",
  //   years: "July 2023 - Present",
  //   content:
  //     "Being Frontend Engineer, I am responsible for designing and development of the platform, creating pixel-perfect designs, writing unit and integration tests, and API integrations.      ",
  // },
  {
    id: 1,
    title: "MERN Stack Freelancer @ Upwork ",
    years: "Jun 2020 - Present",
    content:
      "TOP RATED PLUS Upwork freelancer delivering high-quality deliverables in software development. Worked with startups as well as corporate level companies remotely.",
  },
  {
    id: 2,
    title: "Software Developer @ Creative Handles",
    years: "Sep 2020 - Oct 2021",
    content:
      "As a React.js/Next.js developer contributed to 5+ projects and lead 2 projects. The main role is to create pixel-perfect designs ensuring high-quality code and standards, PR reviews, estimation meetings and deployments.",
  },
  {
    id: 3,
    title: "MERN Instructor",
    years: "Mar 2023 - May 2023",
    content:
      "Had a privilege to teach a full length 3-months long MERN course to a class of 30+ students and professionals. The responsibilities included delivering sessions, hands-on lab, engaging with students and technical assessment.",
  },
  // {
  //   id: 5,
  //   title: "MERN Stack developer @ Hashlogics",
  //   years: "Mar 2020 - Nov 2020",
  //   content:
  //     "As a Lead Front-end developer I helped the company to deliver highly optimized web applications, CMS and Admin Dashboards. The apps got featured in UAE region as well.",
  // },
];

const freelanceData = [
  {
    id: 1,
    title: "MERN Developer @ Raiinmaker",
    years: "Nov 2021 - Jan 2023",
    content:
      "As a FullStack MERN developer, I had been responsible for developing and maintaining admin dashboard for a crypto based reward sharing platforms, creating and integrating API's, refactoring and improving the codebase.",
  },
  {
    id: 2,
    title: "Lead F.E. Developer @ Prism Digitals LLC",
    years: "Mar 2020 - Nov 2020",
    content:
      "As a Lead Front-end developer I helped the company to deliver highly optimized web applications, CMS and Admin Dashboards. The apps got featured in UAE region as well.",
  },
  {
    id: 3,
    title: "MERN Stack developer @ Hashlogics",
    years: "Mar 2020 - Nov 2020",
    content:
      "As a Lead Front-end developer I helped the company to deliver highly optimized web applications, CMS and Admin Dashboards. The apps got featured in UAE region as well.",
  },
  // {
  //   id: 4,
  //   title: "MERN Stack Freelancer @ Upwork ",
  //   years: "Jun 2020 - Present",
  //   content:
  //     " TOP RATED PLUS Upwork freelancer delivering high quality deliverables in software development Worked in the startups as well as in corporate level companies in remote environment.",
  // },
];

function Experiences() {
  return (
    <section id="experience">
      <div className="container">
        <Pagetitle title="Experience" />
        <div className="row">
          <div className="col-md-6">
            <div className="spacer d-md-none d-lg-none" data-height="30"></div>
            <div className="timeline exp bg-white rounded shadow-dark padding-30 overflow-hidden">
              {experienceData.map((experience) => (
                <Timeline key={experience.id} education={experience} />
              ))}
              <span className="line"></span>
            </div>
          </div>
          <div className="col-md-6">
            <div className="timeline exp bg-white rounded shadow-dark padding-30 overflow-hidden mb-4">
              {freelanceData.map((feelance) => (
                <div className="m_b">
                  <Timeline key={feelance.id} education={feelance} />
                </div>
              ))}
              <span className="line"></span>
            </div>
          </div>
        </div>
        <div className="timeline edu bg-white rounded shadow-dark padding-30 overflow-hidden col-12 m-10">
          {educationData.map((education) => (
            <Timeline key={education.id} education={education} />
          ))}
          <span className="line"></span>
        </div>
      </div>
    </section>
  );
}

export default Experiences;
