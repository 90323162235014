import React from "react";
import Slider from "react-slick";
import Pagetitle from "../elements/Pagetitle";
import Testimonial from "../elements/Testimonial";

const sliderData = [
  {
    id: 1,
    avatar: "images/avatar-1.svg",
    userName: "Steve Gauscho",
    subtitle: "CEO @ Viloa Softwares",
    review:
      "Arslan did a fantastic job across multiple projects. He is a great communicator, highly intelligent, and can understand complex requirements. With one project in particular that had some difficult logic, he dug right in and didn't stop until he had the solution figured out, which shows his resilience. I would definitely hire him again and I hope we get to work together again soon!",
  },
  {
    id: 2,
    avatar: "images/avatar-2.svg",
    userName: "Ray Parker",
    subtitle: "Technical Project Manager",
    review:
      "Arslan is a very focused, enthusiastic, and personable individual with great abilities to deliver on technical solutions. He has great insight in planning forward and can always take control of a situation. His strengths of proactively offering solutions and ideas, make him a valuable asset to any situation or company. He owned a large part of our administration portal, and helped with a lot of changes and development efforts that we coordinated across the team.",
  },
  {
    id: 3,
    avatar: "images/avatar-3.svg",
    userName: "Murad Malik",
    subtitle: "CTO @ KuanteX",
    review:
      "Arslan was wonderful to work with. Very attentive and had a lot of great ideas for the project we were working on. One of the things I liked the most about Arslan is his willingness to learn and work on things a little out of his zone. Intelligent, hard-working guy. I would definitely recommend.",
  },
];

function Testimonials() {
  const slidetSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    pauseOnHover: true,
  };

  return (
    <section id="testimonials">
      <div className="container">
        <Pagetitle title="Clients & Reviews" />
        <Slider {...slidetSettings} className="testimonials-wrapper">
          {sliderData.map((slider) => (
            <Testimonial key={slider.id} slider={slider} />
          ))}
        </Slider>
      </div>
    </section>
  );
}

export default Testimonials;
