import React, { useState, useEffect } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import Pagetitle from "../elements/Pagetitle";
import Portfolio from "../elements/Portfolio";

const filters = [
  {
    id: 1,
    text: "Everything",
  },
  {
    id: 2,
    text: "web",
  },
  {
    id: 3,
    text: "mobile",
  },
];

const allData = [
  {
    id: 1,
    title: "Forest.io",
    category: "web",
    image: "images/projects/forest-thumbnail.png",
    popupLink: ["images/projects/forest-full.png"],
    detail:
      "Forest.io is a cleantech AI company that aims to protect our planet by disrupting the voluntary carbon market with transparent offsets.",
    link: "https://forest.io/",
  },
  {
    id: 2,
    title: "Pigeon Arabia",
    category: "web",
    image: "images/projects/pigeon-thumbnail.png",
    popupLink: ["images/projects/pigeon-full.png"],
    link: "https://www.pigeonarabia.com/",
    detail:
      "PIGEON is the top brand in mother and baby care products. It is the name that spells comfort and convenience. ",
  },
  {
    id: 3,
    title: "Luminary Podcasts",
    category: "web",
    image: "images/projects/luminary-thumbnail.png",
    popupLink: ["images/projects/luminary-full.png"],
    link: "https://luminarypodcasts.com/",
    detail:
      "Luminary is a subscription podcast network.Original podcasts you won't find anywhere else.",
  },
  {
    id: 4,
    title: "Raiinmaker",
    category: "mobile",
    image: "images/projects/raiinmaker-thumbnail.jpeg",
    popupLink: ["images/projects/raiinmaker-thumbnail.jpeg"],
    link: "https://play.google.com/store/apps/details?id=com.raiinmaker.app&hl=en&gl=US",
    detail:
      "Raiinmaker, the fastest growing Web 3.0 Social Creator Platform bridges the gap between legacy social media communities.",
  },
  {
    id: 5,
    title: "Hashmeeting",
    category: "mobile",
    image: "images/projects/hashmeeting-thumbnail.png",
    popupLink: ["images/projects/hashmeeting-thumbnail.png"],
    link: "https://play.google.com/store/apps/details?id=com.hashlogics.hashmeeting",
  },
  {
    id: 6,
    title: "Zingaly",
    category: "web",
    image: "images/projects/zingaly-thumbnail.png",
    popupLink: ["images/projects/zingaly-thumbnail.png"],
    link: "https://zignaly.com/app/login",
    detail:
      "To create a social investment platform that connects fund managers with investors worldwide. A platform that offers sophisticated tools.",
  },
  {
    id: 7,
    title: "Latency App",
    category: "web",
    image: "images/projects/latency-thumbnail.png",
    popupLink: ["images/projects/latency-thumbnail.png"],
    link: "https://latencyapp.com/explore",
  },
  {
    id: 8,
    title: "Loonoy (Smart Baby Cradle)",
    category: "mobile",
    image: "images/projects/loonoy.png",
    popupLink: ["images/projects/loonoy-thumbnail.png"],
    link: "https://play.google.com/store/apps/details?id=com.loonoy_mobile",
    detail:
      "The Loonoy smart baby cradle is a high-tech version of the traditional baby cradle, with added features to help ensure the safety.",
  },
  {
    id: 9,
    title: "Fikz (aka Crewchief)",
    category: "mobile",
    image: "images/projects/fikz-thumbnail.jpeg",
    popupLink: ["images/projects/fikz-thumbnail.jpeg"],
    link: "https://appadvice.com/app/fikz/1500930851",
    detail:
      "Fikz is the ideal resource on the Web for people looking to discover iOS apps. We thoroughly filter through the 1,500,000+ ",
  },
];

function Works() {
  const [getAllItems] = useState(allData);
  const [dataVisibleCount, setDataVisibleCount] = useState(6);
  const [dataIncrement] = useState(3);
  const [activeFilter, setActiveFilter] = useState("");
  const [visibleItems, setVisibleItems] = useState([]);
  const [noMorePost, setNoMorePost] = useState(false);

  useEffect(() => {
    setActiveFilter(filters[0].text.toLowerCase());
    setVisibleItems(getAllItems.filter((item) => item.id <= 6));
  }, [getAllItems]);

  const handleChange = (e) => {
    e.preventDefault();
    setActiveFilter(e.target.textContent.toLowerCase());
    let tempData;
    if (e.target.textContent.toLowerCase() === filters[0].text.toLowerCase()) {
      tempData = getAllItems.filter((data) => data.id <= dataVisibleCount);
    } else {
      tempData = getAllItems.filter(
        (data) =>
          data.category === e.target.textContent.toLowerCase() &&
          data.id <= dataVisibleCount
      );
    }
    setVisibleItems(tempData);
  };

  const handleLoadmore = (e) => {
    e.preventDefault();
    let tempCount = dataVisibleCount + dataIncrement;
    if (dataVisibleCount > getAllItems.length) {
      setNoMorePost(true);
    } else {
      setDataVisibleCount(tempCount);
      if (activeFilter === filters[0].text.toLowerCase()) {
        console.log("they are same");
        setVisibleItems(getAllItems.filter((data) => data.id <= tempCount));
      } else {
        setVisibleItems(
          getAllItems.filter(
            (data) => data.category === activeFilter && data.id <= tempCount
          )
        );
      }
    }
  };

  return (
    <section id="works">
      <div className="container">
        <Pagetitle title="Recent Projects" />
        {/* Start Portfolio Filters */}
        <ScrollAnimation
          animateIn="fadeInUp"
          animateOut="fadeInOut"
          animateOnce={true}
        >
          <ul className="portfolio-filter list-inline">
            {filters.map((filter) => (
              <li className="list-inline-item" key={filter.id}>
                <button
                  onClick={handleChange}
                  className={
                    filter.text.toLowerCase() === activeFilter
                      ? "text-capitalize current"
                      : "text-capitalize"
                  }
                >
                  {filter.text}
                </button>
              </li>
            ))}
          </ul>
        </ScrollAnimation>
        {/* End Portfolio Filters */}

        {/* Start Portfolio Items */}
        <div className="row portfolio-wrapper">
          {visibleItems.map((item) => (
            <div className="col-md-4 col-sm-6 grid-item" key={item.id}>
              <Portfolio portfolio={item} />
            </div>
          ))}
        </div>
        {/* End Portfolio Items */}

        <div className="load-more text-center mt-4">
          <button
            className="btn btn-default"
            onClick={handleLoadmore}
            disabled={noMorePost ? "disabled" : null}
          >
            {noMorePost ? (
              ""
            ) : (
              <span>
                <i className="fas fa-spinner"></i> Load more
              </span>
            )}
          </button>
        </div>
      </div>
    </section>
  );
}

export default Works;
