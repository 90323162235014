import React from "react";
import Typed from "react-typed";
import { Link } from "react-scroll";
import ParallaxElements from "../elements/ParallaxElements";

function Herosection(props) {
  return (
    <section
      id="home"
      className={
        props.light
          ? "home d-flex align-items-center light"
          : "home d-flex align-items-center"
      }
    >
      <div className="container">
        <div className="intro">
          <img src="images/profile-pic.png" alt="Arslan" className="mb-4" />

          <h1 className="mb-2 mt-0">Arslan Shahab</h1>
          <p>
            I'm a{" "}
            <Typed
              strings={[
                "",
                "Front-End developer",
                "Mobile App Developer",
                "Backend Developer",
              ]}
              typeSpeed={80}
              backSpeed={40}
              attr="value"
              loop
            >
              <label value></label>
            </Typed>
          </p>

          <ul className="social-icons light list-inline mb-0 mt-4">
            <li className="list-inline-item">
              <a href="https://github.com/arslanshahab">
                <i className="fab fa-github"></i>
              </a>
            </li>
            {/* <li className="list-inline-item">
              <a href="https://www.linkedin.com/in/arslanshahab/">
                <i className="fab fa-linkedin text-primary"></i>
              </a>
            </li> */}
            {/* <li className="list-inline-item">
              <a href="https://www.upwork.com/freelancers/arslanshahab">
                <img
                  src="/images/upwork2.png"
                  alt="upwork"
                  style={{
                    width: '25px',
                    height: '25px',
                    marginTop:"-5px"
                  }}
                />
              </a>
            </li> */}
            <li className="list-inline-item">
              <a href="https://www.instagram.com/arslanshahab/">
                <i
                  className="fab fa-instagram"
                  style={{ color: "#dd1c53" }}
                ></i>
              </a>
            </li>
            <li className="list-inline-item">
              <a href="https://twitter.com/arslan_shahab">
                <i className="fab fa-twitter" style={{ color: "#00a9eb" }}></i>
              </a>
            </li>
            <li className="list-inline-item">
              {/* <a target="_blank" class="icon-content-left text-decoration-none" href="https://api.whatsapp.com/send?phone=16282292879"></a> */}
              <a
                target="_blank"
                className="btn-link"
                href="https://api.whatsapp.com/send?phone=923206625492"
              >
                <i class="fab fa-whatsapp text-success"></i>
              </a>
            </li>
          </ul>

          <div className="mt-4">
            <Link
              className="btn btn-default"
              to="section-contact"
              spy={true}
              smooth={true}
              duration={500}
            >
              Hire me
            </Link>
          </div>
        </div>

        <div className="scroll-down">
          <Link
            to="section-about"
            spy={true}
            smooth={true}
            duration={500}
            className="mouse-wrapper"
          >
            <span>Scroll Down</span>
            <span className="mouse">
              <span className="wheel"></span>
            </span>
          </Link>
        </div>

        {props.position && (
          <ParallaxElements
            elementDimensions={props.elementDimensions}
            position={props.position}
          />
        )}
      </div>
    </section>
  );
}

export default Herosection;
